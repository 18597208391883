import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAlarmExclamation,
  faDownload,
  faEnvelope,
  faEye,
  faTrash,
  faUserGroup,
  faSolarSystem,
  faGear,
  faGlobe,
  faClipboard,
  faMessages,
  faClock,
  faShareNodes,
  faPen,
  faList,
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faAlarmExclamation,
  faDownload,
  faEnvelope,
  faEye,
  faTrash,
  faUserGroup,
  faSolarSystem,
  faGear,
  faClipboard,
  faGlobe,
  faMessages,
  faClock,
  faShareNodes,
  faPen,
  faList,
)
