import type { RouteLocationNormalized } from 'vue-router'
import { PAGE_NOT_FOUND } from '../routes'
import { findLast } from 'lodash'
import { PAGE_SESSIONS_ON_GOING_CHALLENGES } from '@/router/routes'
import useLocale, { i18nLocale } from '@/hooks/useLocale'

const { setLocale, currentLocale, isLocaleSupported, isLocaleToShowTranslatedKeys, getDefaultLocale } = useLocale()

export default class LocaleGuard {
  public static checkLocale(to: RouteLocationNormalized, from: RouteLocationNormalized | null) {
    const hasLocaleParam = to.params.lang?.toString() as i18nLocale
    // Route is OK - has the custom prefix like:
    // ('/custom/MY_ROUTE_PATH')
    if (isLocaleToShowTranslatedKeys(hasLocaleParam)) {
      setLocale(hasLocaleParam)
      return null
    }

    const userLocale = currentLocale.value
    if (hasLocaleParam && userLocale !== undefined && hasLocaleParam !== userLocale) {
      return {
        ...to,
        params: {
          ...to.params,
          lang: userLocale,
        },
      }
    }

    // Route is not OK, we need to add a locale param:
    // - no param locale
    // - param locale not supporter
    if (!hasLocaleParam || !isLocaleSupported(hasLocaleParam)) {
      const matchedWithName = findLast(to.matched, (a) => a.name !== null)

      if (matchedWithName !== null && matchedWithName.name !== PAGE_NOT_FOUND) {
        return {
          name: matchedWithName.name,
          params: {
            ...to.params,
            lang: getDefaultLocale(),
          },
          query: to.query,
          hash: to.hash,
        }
      }
      // Route is not OK
      // can be a 404 with a locale param or not
      return this.getRedirectionWithLocalePrefix(to)
    }

    // Locale is OK, but with an empty path
    // ex: /fr
    if (to.name === undefined) {
      return {
        name: PAGE_SESSIONS_ON_GOING_CHALLENGES,
        params: {
          ...to.params,
          lang: hasLocaleParam,
        },
        query: to.query,
        hash: to.hash,
      }
    }

    setLocale(hasLocaleParam)

    return null
  }

  private static getRedirectionWithLocalePrefix(to: RouteLocationNormalized) {
    const [lang] = to.path.split('/')
    if (lang) {
      // if url is something like /test
      if (!isLocaleSupported(lang as i18nLocale)) {
        const i18nFullPath = `/${currentLocale.value}${to.fullPath}`

        return {
          fullPath: i18nFullPath,
          path: i18nFullPath,
          params: { ...to.params, lang: currentLocale.value },
          query: to.query,
        }
      }

      // the url can be /fr/test
      return null
    }

    return null
  }
}
