import type { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import type { FiftyApiClientManager } from '@/store/services/apiClient/FiftyApiClientManager'
import { OnBoardingHelper } from '@/components/onBoarding/OnBoardingHelper'

export const COMMIT_SAVE_OB_DATA = 'OnBoardingStore/save'
export const COMMIT_COMPUTE_STEPS = 'OnBoardingStore/computeSteps'
export const DISPATCH_REFRESH_OB_DATA = 'OnBoardingStore/refresh'
export const GET_ON_BOARDING_DATA = 'OnBoardingStore/getOnBoardingData'
export const GETTER_CURRENT_STEP = 'OnBoardingStore/getCurrentStep'
export const GETTER_TOTAL_STEP_COUNT = 'OnBoardingStore/getTotalStepCount'

export interface ISaveCurrentStepPayload {
  step: number
}

export interface IRefreshOBDataPayload {
  apiClients: FiftyApiClientManager
  clientCode?: string
  programCode?: string
  language?: FiftyApiClients.SupportedLanguageDto
  userMail?: string
  forceRefresh?: boolean
  component?: any
}

export interface IOnBoardingState {
  onBoardingData: FiftyApiClients.OnBoardingDataDto | null
  allSteps: string[]
  hasFinishedLastStep: boolean // to have the animation that fills the progress bar at the end
}

export const OnBoardingStore = {
  namespaced: true,
  state: {
    onBoardingData: null,
    hasFinishedLastStep: false,
    allSteps: [],
  },
  mutations: {
    save(state: IOnBoardingState, onBoardingData: FiftyApiClients.OnBoardingDataDto) {
      state.onBoardingData = onBoardingData
    },
    computeSteps(state: IOnBoardingState) {
      if (state.onBoardingData) {
        state.allSteps = OnBoardingHelper.getAllSteps(state.onBoardingData)
      }
    },
  },
  actions: {
    async refresh({ commit, getters }: any, payload: IRefreshOBDataPayload) {
      if (!payload.forceRefresh) {
        const result: FiftyApiClients.OnBoardingDataDto = getters.getOnBoardingData

        if (result) {
          return result
        }
      }

      if (payload.component) {
        payload.component.isLoading = true
      }

      try {
        let response: FiftyApiClients.ApiResponseOfOnBoardingDataDto | null = null
        if (payload.clientCode && payload.language !== null && payload.language !== undefined) {
          response = await payload.apiClients.mainUserOnBoardingApiClient.getOnBoardingDataFromCode(
            payload.clientCode,
            payload.language,
          )
        } else if (
          !payload.clientCode &&
          payload.programCode &&
          payload.language !== null &&
          payload.language !== undefined
        ) {
          response = await payload.apiClients.mainUserOnBoardingApiClient.getOnBoardingDataFromProgramCode(
            payload.programCode,
            payload.language,
          )
        } else if (payload.userMail) {
          response = await payload.apiClients.mainUserOnBoardingApiClient.getOnBoardingDataFromEmail(payload.userMail)
        }
        if (!response || !response.result) {
          return false
        }
        commit(COMMIT_SAVE_OB_DATA, response.result, { root: true })
        commit(COMMIT_COMPUTE_STEPS, null, { root: true })
        return response.result
      } catch (e) {
        if (payload.clientCode && payload.language !== null && payload.language !== undefined && payload.component) {
          payload.component.displayUnknownCodeError = true
        }
        if (payload.component) {
          payload.component.$logger.logError(e)
        } else {
          console.error(e)
        }
        return false
      }
    },
  },
  getters: {
    getOnBoardingData: (state: IOnBoardingState): FiftyApiClients.OnBoardingDataDto | null => state.onBoardingData,
    getCurrentStep: (state: IOnBoardingState) => (currentPage: string) => {
      if (state.hasFinishedLastStep) return state.allSteps.length

      return OnBoardingHelper.getStepIndex(currentPage, state.allSteps)
    },
    getTotalStepCount: (state: IOnBoardingState): number => state.allSteps.length,
  },
}
