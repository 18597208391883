import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import type { DispatchRawPayload } from '@/store/modules/BaseStore'
import {
  COMMIT_INCREMENT_ACCEPTATION_STAT,
  COMMIT_INCREMENT_HABIT_STAT,
  COMMIT_PHONE_NUMBER,
  DISPATCH_INCREMENT_VALIDATION_STAT,
} from '@/store/modules/Login'
import { COMMIT_CHALLENGE_VALIDATE, COMMIT_SET_HABIT, COMMIT_UNSET_HABIT } from '@/store/modules/ChallengesStore'
import type { ISessionCommit } from '@/store/modules/ChallengesStore'

// load
export const DISPATCH_LOAD_ALL_HABITS = 'HabitsStore/loadAllHabits'
export const COMMIT_HABITS = 'saveHabits'

// create
export const DISPATCH_CREATE_HABIT = 'HabitsStore/createHabit'
export const COMMIT_ADD_HABIT = 'addNewHabit'

// get
export const GET_HABITS = 'HabitsStore/getHabits'
export const GET_ALL_ON_GOING_HABITS = 'HabitsStore/getAllOnGoingHabits'
export const GET_ALL_ON_GOING_HABITS_WITH_PROGRAM_ID = 'HabitsStore/getAllOnGoingHabitsWithProgramId'
export const GET_ALL_ACQUIRED_HABITS_WITH_PROGRAM_ID = 'HabitsStore/getAllAcquiredHabitsWithProgramId'
export const GET_HABIT_FROM_CHALLENGE_ID = 'HabitsStore/getHabitFromChallengeId'

// validate
export const DISPATCH_VALIDATE_HABIT = 'HabitsStore/validateHabit'
export const COMMIT_HABIT_VALIDATION = 'saveHabitValidation'

// acquire
export const DISPATCH_ACQUIRE_HABIT = 'HabitsStore/acquireHabit'
export const COMMIT_ACQUIRED_HABIT = 'saveAcquiredHabit'

// stop
export const DISPATCH_STOP_HABIT = 'HabitsStore/stopHabit'
export const COMMIT_REMOVE_HABIT = 'removeHabit'

// resume
export const DISPATCH_RESUME_HABIT = 'HabitsStore/resumeHabit'
export const COMMIT_SAVE_HABIT = 'saveHabit'

// reschedule
export const DISPATCH_RESCHEDULE_HABIT = 'HabitsStore/rescheduleHabit'

interface IHabitsState {
  habits: FiftyApiClients.HabitDto[] | null
  isLoadingCurrentHabits: boolean
}

export interface ICreateHabitPayload extends DispatchRawPayload {
  challengeId: string
  isCustomChallenge: boolean
  periodicity: number
  reminderDayOfWeek: number
  reminders: FiftyApiClients.HabitReminderDto[]
  phoneNumber: string
}

export interface IValidateHabitPayload extends DispatchRawPayload {
  habitId: string
  challengeId: string
}

export interface IStopHabitPayload extends DispatchRawPayload {
  habitId: string
  challengeId: string
}

interface ICommitHabitValidationPayload {
  habitId: string
  habitPeriod: FiftyApiClients.HabitPeriodDto
  totalCount: number
}

export interface IRescheduleHabitPayload extends DispatchRawPayload {
  habitId: string
  periodicity: number
  reminderDayOfWeek: number
  reminders: FiftyApiClients.HabitReminderDto[]
  phoneNumber: string
}

export type ILoadAllHabitsPayload = DispatchRawPayload

export interface IAcquireHabitPayload extends DispatchRawPayload {
  habitId: string
}

export interface IResumeHabitPayload extends DispatchRawPayload {
  habitId: string
  periodicity: number
  reminderDayOfWeek: number
  reminders: FiftyApiClients.HabitReminderDto[]
  phoneNumber: string
}

interface IHabitsStore {
  namespaced: boolean
  state: IHabitsState
  mutations: any
  actions: any
  getters: any
}

export const HabitsStore: IHabitsStore = {
  namespaced: true,
  state: {
    habits: [],
    isLoadingCurrentHabits: true,
  } as IHabitsState,
  mutations: {
    addNewHabit(state: IHabitsState, habit: FiftyApiClients.HabitDto) {
      if (habit && state.habits && !state.habits.find((h) => h.habitId === habit.habitId)) {
        state.habits.push(habit)
      }
    },
    saveHabits(state: IHabitsState, habits: FiftyApiClients.HabitDto[]) {
      state.habits = habits
    },
    saveHabitValidation(state: IHabitsState, validationPayload: ICommitHabitValidationPayload) {
      if (!validationPayload || !validationPayload.habitPeriod || !state.habits) return

      const habit = state.habits.find((x) => x.habitId === validationPayload.habitId)
      if (!habit || !habit.usage) return

      const habitPeriod = habit.usage.habitPeriods?.find((x) => x.id === validationPayload.habitPeriod?.id)
      if (!habitPeriod) return

      habitPeriod.status = validationPayload.habitPeriod.status
      habit.usage.totalDone = validationPayload.totalCount
    },
    saveAcquiredHabit(state: IHabitsState, habitId: string) {
      if (!habitId || !state.habits) return

      const habit = state.habits.find((x) => x.habitId === habitId)
      if (!habit) return

      habit.status = FiftyApiClients.HabitStatusDto.Acquired
    },
    saveHabit(state: IHabitsState, habit: FiftyApiClients.HabitDto) {
      if (!habit || !state.habits) return

      const storeHabitIndex = state.habits.findIndex((x) => x.habitId === habit.habitId)

      if (storeHabitIndex === -1) return

      state.habits.splice(storeHabitIndex, 1)
      state.habits.push(habit)
    },
    removeHabit(state: IHabitsState, habitId: string) {
      if (!habitId || !state.habits) return

      const storeHabitIndex = state.habits.findIndex((x) => x.habitId === habitId)

      if (storeHabitIndex === -1) return

      state.habits.splice(storeHabitIndex, 1)
    },
    setIsLoading(state: IHabitsState, isLoading: boolean) {
      state.isLoadingCurrentHabits = isLoading
    },
  },
  actions: {
    async createHabit({ commit }: any, payload: ICreateHabitPayload): Promise<boolean> {
      const request = {
        periodicity: payload.periodicity,
        reminderDayOfWeek: payload.reminderDayOfWeek,
        programActionId: payload.isCustomChallenge ? null : payload.challengeId,
        customChallengeId: payload.isCustomChallenge ? payload.challengeId : null,
        reminders: payload.reminders,
        phoneNumber: payload.phoneNumber,
      } as FiftyApiClients.CreateHabitRequest

      const res = await payload.apiClients.habitApiClient.createHabit(request)
      if (!res.result || (res.errors && res.errors.length > 0)) return false

      commit(COMMIT_ADD_HABIT, res.result.habitData)

      const sessionPayload = {
        challengeId: request.programActionId ?? request.customChallengeId,
      } as ISessionCommit
      commit(COMMIT_SET_HABIT, sessionPayload, { root: true })
      commit(COMMIT_PHONE_NUMBER, request.phoneNumber, { root: true })
      return true
    },
    async loadAllHabits({ commit }: any, payload: ILoadAllHabitsPayload) {
      commit('setIsLoading', true)
      await payload.apiClients.habitApiClient.getHabits().then((res: FiftyApiClients.ApiResponseOfListOfHabitDto) => {
        if (res.result && (!res.errors || res.errors.length === 0)) {
          commit(COMMIT_HABITS, res.result)
        }
      })
      commit('setIsLoading', false)
    },
    async validateHabit({ commit }: any, payload: IValidateHabitPayload) {
      await payload.apiClients.habitApiClient
        .validateChallenge(payload.habitId)
        .then((res: FiftyApiClients.ApiResponseOfValidateHabitResponse) => {
          if (res.result && (!res.errors || res.errors.length === 0)) {
            const commitPayload = {
              habitId: payload.habitId,
              habitPeriod: res.result.habitPeriod,
              totalCount: res.result.newTotalCount,
            } as ICommitHabitValidationPayload

            commit(COMMIT_HABIT_VALIDATION, commitPayload)
            commit(COMMIT_CHALLENGE_VALIDATE, payload.challengeId, { root: true })
            commit(COMMIT_INCREMENT_ACCEPTATION_STAT, null, { root: true })
            commit(DISPATCH_INCREMENT_VALIDATION_STAT, null, { root: true })
          }
        })
    },
    async acquireHabit({ commit }: any, payload: IAcquireHabitPayload): Promise<boolean> {
      const res = await payload.apiClients.habitApiClient.acquireHabit(payload.habitId)
      if (!res.result || !res.result.isSuccessful || (res.errors && res.errors.length > 0)) return false

      commit(COMMIT_ACQUIRED_HABIT, payload.habitId)

      if (res.result.isANewHabit) {
        commit(COMMIT_INCREMENT_HABIT_STAT, null, { root: true })
      }

      return res.result.isSuccessful
    },
    async resumeHabit({ commit }: any, payload: IResumeHabitPayload): Promise<boolean> {
      const request = {
        periodicity: payload.periodicity,
        reminderDayOfWeek: payload.reminderDayOfWeek,
        reminders: payload.reminders,
        phoneNumber: payload.phoneNumber,
      } as FiftyApiClients.ResumeHabitRequest

      const res = await payload.apiClients.habitApiClient.resumeHabit(payload.habitId, request)
      if (!res.result || (res.errors && res.errors.length > 0)) return false

      commit(COMMIT_SAVE_HABIT, res.result!)
      commit(COMMIT_PHONE_NUMBER, request.phoneNumber, { root: true })

      return true
    },
    async rescheduleHabit({ state, commit, dispatch }: any, payload: IRescheduleHabitPayload): Promise<boolean> {
      const request = {
        periodicity: payload.periodicity,
        reminderDayOfWeek: payload.reminderDayOfWeek,
        reminders: payload.reminders,
        phoneNumber: payload.phoneNumber,
      } as FiftyApiClients.RescheduleHabitRequest
      const res = await payload.apiClients.habitApiClient.rescheduleHabit(payload.habitId, request)
      if (!res.result || (res.errors && res.errors.length > 0 && !res.result.isSuccessful)) return false

      commit(COMMIT_SAVE_HABIT, res.result.updatedHabit)
      commit(COMMIT_PHONE_NUMBER, request.phoneNumber, { root: true })
      return true
    },
    async stopHabit({ state, commit, dispatch }: any, payload: IStopHabitPayload): Promise<boolean> {
      const res = await payload.apiClients.habitApiClient.stopHabit(payload.habitId)
      if (!res.result || (res.errors && res.errors.length > 0 && !res.result.isStopped)) return false

      if (res.result.isAcquired) {
        commit(COMMIT_ACQUIRED_HABIT, payload.habitId)
        commit(COMMIT_INCREMENT_ACCEPTATION_STAT, null, { root: true })
      } else {
        commit(COMMIT_REMOVE_HABIT, payload.habitId)
        commit(COMMIT_UNSET_HABIT, payload.challengeId, { root: true })
      }
      return true
    },
  },
  getters: {
    getHabits(state: IHabitsState): FiftyApiClients.HabitDto[] {
      return state.habits ?? []
    },
    getAllOnGoingHabits(state: IHabitsState): FiftyApiClients.HabitDto[] {
      return state.habits?.filter((x) => x.status === FiftyApiClients.HabitStatusDto.Ongoing) ?? []
    },
    getAllOnGoingHabitsWithProgramId: (state: IHabitsState) => (programId: string) =>
      state.habits?.filter(
        (x) =>
          x.status === FiftyApiClients.HabitStatusDto.Ongoing &&
          x.challengeData?.courseChallenges &&
          x.programId === programId,
      ),
    getAllAcquiredHabitsWithProgramId: (state: IHabitsState) => (programId: string) =>
      state.habits?.filter(
        (x) =>
          x.status === FiftyApiClients.HabitStatusDto.Acquired &&
          x.challengeData?.courseChallenges &&
          x.programId === programId,
      ),
    getHabitFromChallengeId: (state: IHabitsState) => (challengeId: string) =>
      state.habits?.find(
        (x) =>
          x.challengeData?.courseChallenges && x.challengeData?.courseChallenges[0].programActionId === challengeId,
      ),
  },
}
