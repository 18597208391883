import { $segment } from '@/globalProperties'
import type { TrackEventType } from '@/store/services/SegmentHelper'

export default {
  beforeMount(el: Element, { arg, value }: { arg: string; value: TrackEventType }) {
    el.addEventListener(arg, () => $segment.track(value))
  },
  beforeUnmount(el: Element, { arg, value }: { arg: string; value: TrackEventType }) {
    el.removeEventListener(arg, () => $segment.track(value))
  },
}
