import type { FiftyApiClientManager } from '@/store/services/apiClient/FiftyApiClientManager'

export default class VersionManager {
  private apiClients: FiftyApiClientManager
  private static apiVersionKey = 'api_version'

  constructor(apiClients: FiftyApiClientManager) {
    this.apiClients = apiClients
  }

  public checkVersion(): void {
    this.apiClients.statusApiClient.getVersion().then((res) => {
      if (!res || !res.result) return

      const currentServerVersion = res.result.version

      const frontendVersion = VersionManager.getFrontendLocalVersion()

      // we are in dev mode
      if (frontendVersion === null || frontendVersion === '{{version}}' || frontendVersion === '1.0.0') {
        return
      }

      const apiLocalVersion = VersionManager.getLocalApiVersion()
      if (!apiLocalVersion) {
        VersionManager.setLocalApiVersion(currentServerVersion)
      } else if (apiLocalVersion < currentServerVersion) {
        VersionManager.setLocalApiVersion(currentServerVersion)

        VersionManager.forceReload()
      }
    })
  }

  public static getFrontendLocalVersion(): string | null {
    return import.meta.env.VITE_APP_VERSION
  }

  public static getLocalApiVersion(): string | null {
    return localStorage.getItem(this.apiVersionKey)
  }

  public static setLocalApiVersion(val: string): void {
    localStorage.setItem(this.apiVersionKey, val)
  }

  public static forceReload(): void {
    // we need to force the reload... because of caching
    // noinspection JSDeprecatedSymbols
    window.location.reload()
  }
}
