import { Challenge } from '@/store/FiftyTypes'

export default class ChallengeDtoHelper {
  // not specifying ChallengeDto type as there is a problem with the import if I do...
  public static challengeDtoToChallenge(dtoChallenges: any[]): Challenge[] {
    const challenges: Challenge[] = []
    if (!dtoChallenges) return challenges

    for (let i = 0; i < dtoChallenges.length; i++) {
      const dtoChallenge: any = dtoChallenges[i]
      for (let j = 0; j < dtoChallenge.courseChallenges!.length; j++) {
        challenges.push(new Challenge(dtoChallenge, dtoChallenge.courseChallenges![j]))
      }
    }

    return challenges
  }
}
