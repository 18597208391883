import type { Router } from 'vue-router'
import type { FiftyApiClientManager } from '../services/apiClient/FiftyApiClientManager'
import type { SegmentHelper } from '../services/SegmentHelper'
import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import { PAGE_LOGIN, PAGE_USER_WITH_TEAMS_SIGNUP } from '@/router/routes'
import {
  CLEAR_SESSION,
  DISPATCH_LOAD_SESSIONS_DATA,
  type IRetrieveSessionsPayload,
} from '@/store/modules/ChallengeSession'
import LoginManager, { USER_LOGGED_WITH_TEAMS_KEY } from '@/store/services/LoginManager'
import { CLEAR_CHALLENGES } from '@/store/modules/ChallengesStore'
import { PushHelper } from '@/store/services/PushHelper'
import FiftySourceHelper from '@/store/services/FiftySourceHelper'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import useUser from '@/hooks/useUser'

import useLocale, { i18nLocale } from '@/hooks/useLocale'
import { DISPATCH_LOAD_ALL_HABITS, type ILoadAllHabitsPayload } from '@/store/modules/HabitsStore'

dayjs.extend(timezone)

const { currentLocale, setLocale, isLocaleToShowTranslatedKeys, getLanguageStringFromDto } = useLocale()
const { setCurrentUser } = useUser()

interface ILoginModuleState {
  user: FiftyApiClients.UserDto | null
  isInit: boolean
  hasError: boolean
}

export interface IConnectPayload {
  user: FiftyApiClients.UserDto
  segment: SegmentHelper
}

export interface ISaveSegment {
  segmentNumber: number
  segmentId: string
}

export interface IGetUserProfilePayload {
  router: Router
  apiClients: FiftyApiClientManager
  segment: SegmentHelper
}

export type IInitUserPayload = IGetUserProfilePayload

export interface IRefreshPayload {
  apiClients: FiftyApiClientManager
  segment: SegmentHelper
  currentProgression: number | undefined
}

export interface ITechnicalUpdatePayload {
  apiClients: FiftyApiClientManager
}

export interface IRefreshInstallationPayload {
  apiClients: FiftyApiClientManager
  segment: SegmentHelper
}

export interface ILogOutPayload {
  router: Router
  apiClients: FiftyApiClientManager
}

export const GETTER_USER = 'Login/getUser'
export const GETTER_IS_AUTHENTICATED = 'Login/isAuthenticated'
export const GETTER_IS_BO_ADMIN = 'Login/canAccessAdminBo'
export const GETTER_IS_SUPER_ADMIN = 'Login/canAccessSuperAdminFeatures'
export const GETTER_IS_BREAK_ENABLED = 'Login/isBreakEnabled'
export const GETTER_IS_USER_ON_BREAK = 'Login/isUserOnBreak'
export const HAS_MORE_THAN_ONE_COURSE = 'Login/hasMoreThanOneCourse'
export const GETTER_IS_INIT = 'Login/isInit'

export const COMMIT_OB_SEGMENTS = 'Login/hasPassedOnBoardingSegments'
export const COMMIT_OB_HAS_FINISHED_ON_BOARDING = 'Login/hasFinishedOnBoarding'
export const COMMIT_OB_STATUS = 'Login/updateObStatus'

export const COMMIT_CONNECT = 'Login/connect'
export const COMMIT_SOFT_DISCONNECT = 'Login/softDisconnect'
export const COMMIT_HARD_DISCONNECT = 'Login/hardDisconnect'
export const COMMIT_INNER_SOFT_DISCONNECT = 'softDisconnect'
export const COMMIT_INNER_HARD_DISCONNECT = 'hardDisconnect'
export const COMMIT_INNER_CONNECT = 'connect'
export const COMMIT_INNER_REFRESH_TEAMS_INSTALLATION = 'refreshTeamsInstallation'
export const COMMIT_INNER_REFRESH_MOBILE_APP_INSTALLATION = 'refreshMobileAppInstallation'
export const COMMIT_SET_CURRENT_COURSE_ID = 'Login/setCurrentUserCampaignId'
export const COMMIT_SET_ACCOUNT_OFFICIALLY_CREATED = 'Login/setAccountOfficiallyCreated'

export const COMMIT_DECREMENT_USER_UNSEEN_COMMENTS_COUNT = 'Login/decrementUnseenCommentsCount'

export const COMMIT_INCREMENT_ACCEPTATION_STAT = 'Login/incrementAcceptationStat'
export const COMMIT_INNER_ACCEPTATION_STAT = 'incrementAcceptationStat'
export const INNER_COMMIT_INCREMENT_VALIDATION_STAT = 'incrementValidationStat'
export const INNER_COMMIT_INCREMENT_VALIDATION_STAT_V2 = 'incrementValidationStatV2'
export const COMMIT_INCREMENT_HABIT_STAT = 'Login/incrementHabitStat'
export const DISPATCH_INCREMENT_ACCEPTATION_STAT = 'Login/incrementAcceptationStat'

export const COMMIT_NEW_TEAM = 'Login/saveNewTeam'

export const DISPATCH_INIT_USER_DATA = 'Login/getUserData'
export const DISPATCH_UPDATE_TECHNICAL_USER_DATA = 'Login/technicalUpdate'
export const DISPATCH_GET_USER_PROFILE = 'Login/getUserProfile'
export const DISPATCH_REFRESH_USER = 'Login/refresh'
export const INNER_DISPATCH_REFRESH_USER = 'refresh'
export const DISPATCH_INCREMENT_VALIDATION_STAT = 'Login/incrementValidationStat'
export const DISPATCH_LOGOUT = 'Login/logOut'
export const DISPATCH_REFRESH_TEAMS_INSTALLATION = 'Login/refreshTeamsInstallation'
export const DISPATCH_REFRESH_MOBILE_APP_INSTALLATION = 'Login/refreshMobileAppInstallation'

export const COMMIT_PHONE_NUMBER = 'Login/setPhoneNumber'

export const COMMIT_SEGMENTS = 'Login/setSegments'
export const COMMIT_PROFILE = 'Login/setProfile'

export const COMMIT_BOOSTER_OB_DONE = 'Login/setBoosterObDone'

export const Login = {
  namespaced: true,
  state: {
    isInit: false,
    user: null,
  } as ILoginModuleState,
  mutations: {
    connect(state: ILoginModuleState, payload: IConnectPayload) {
      state.isInit = true
      state.user = payload.user
      setCurrentUser(payload.user)
      payload.segment.identify(payload.user.id)
    },
    softDisconnect(state: ILoginModuleState, origin: string) {
      state.isInit = true
      state.user = null
      setCurrentUser(null)
      LoginManager.setSoftLogout(origin)
    },
    hardDisconnect(state: ILoginModuleState, origin: string) {
      state.isInit = true
      state.user = null
      setCurrentUser(null)
      LoginManager.setLoggedOut(origin)
    },
    hasPassedOnBoardingSegments(state: ILoginModuleState, params: FiftyApiClients.UserSegmentDto[]) {
      if (!state.user || !state.user.currentUserCampaign) return

      state.user.segments = params
    },
    hasFinishedOnBoarding(state: ILoginModuleState) {
      if (!state.user || !state.user.currentUserCampaign) return

      state.user.currentUserCampaign.onBoardingStatus = FiftyApiClients.OnBoardingStatusDto.HasFinishedOnBoarding
    },
    updateObStatus(state: ILoginModuleState, status: FiftyApiClients.OnBoardingStatusDto) {
      if (!state.user || !state.user.currentUserCampaign) return

      state.user.currentUserCampaign.onBoardingStatus = status
    },
    setPhoneNumber(state: ILoginModuleState, phoneNumber: string) {
      if (!phoneNumber || !state.user) return
      state.user.phoneNumber = phoneNumber
    },
    setCurrentUserCampaignId(state: ILoginModuleState, courseId: string) {
      if (!state.user?.currentUserCampaign) {
        return
      }

      // To set courseId after OnBoarding campaign course choice
      state.user.currentUserCampaign.programId = courseId
    },
    setAccountOfficiallyCreated(state: ILoginModuleState) {
      // To set courseId after OnBoarding campaign course choice
      state.user!.isAccountOfficiallyCreated! = true
    },
    decrementUnseenCommentsCount(state: ILoginModuleState, nbUnseenCountToRemove: number) {
      state.user!.unseenCommentCount! -= nbUnseenCountToRemove
    },
    incrementAcceptationStat(state: ILoginModuleState) {
      state.user!.globalProgress!.acceptChallengeCount! += 1
    },
    incrementValidationStat(state: ILoginModuleState) {
      state.user!.globalProgress!.validatedChallengeCount! += 1
      state.user!.currentBadge!.validatedCount! += 1
    },
    incrementValidationStatV2(state: ILoginModuleState, currentProgression: number) {
      state.user!.globalProgress!.validatedChallengeCount! +=
        currentProgression - state.user!.currentBadge!.validatedCount!
      state.user!.currentBadge!.validatedCount! = currentProgression
    },
    incrementHabitStat(state: ILoginModuleState) {
      state.user!.globalProgress!.habitCount! += 1
    },
    refreshTeamsInstallation(state: ILoginModuleState, hasTeamsInstalled: boolean) {
      state.user!.hasTeamsInstalled = hasTeamsInstalled
    },
    refreshMobileAppInstallation(state: ILoginModuleState, hasMobileAppInstalled: boolean) {
      state.user!.hasMobileAppInstalled = hasMobileAppInstalled
    },
    saveNewTeam(state: ILoginModuleState, newTeam: FiftyApiClients.UserTeamDto) {
      if (!state.user!.userTeams) {
        state.user!.userTeams = []
      }
      state.user!.userTeams.push(newTeam)
    },
    setSegments(state: ILoginModuleState, params: FiftyApiClients.UserSegmentDto[]) {
      if (!state.user || !state.user.currentUserCampaign || !params) return

      state.user.segments = params
    },
    setProfile(state: ILoginModuleState, profile: FiftyApiClients.ManagerProfileDto) {
      if (!state.user) return

      state.user.managerProfile = profile
    },
    setBoosterObDone(state: ILoginModuleState) {
      if (!state.user?.currentUserCampaign) return
      state.user.currentUserCampaign.onBoardingStatus = FiftyApiClients.OnBoardingStatusDto.HasFinishedOnBoarding
    },
  },
  actions: {
    async getUserData({ state, commit, dispatch }: any, payload: IInitUserPayload) {
      try {
        await dispatch(DISPATCH_GET_USER_PROFILE, payload, { root: true })

        dispatch(
          DISPATCH_UPDATE_TECHNICAL_USER_DATA,
          {
            apiClients: payload.apiClients,
          },
          { root: true },
        )

        const getSessionsPayload = { apiClients: payload.apiClients } as IRetrieveSessionsPayload
        await dispatch(DISPATCH_LOAD_SESSIONS_DATA, getSessionsPayload, { root: true })

        const getHabitsPayload = { apiClients: payload.apiClients } as ILoadAllHabitsPayload
        await dispatch(DISPATCH_LOAD_ALL_HABITS, getHabitsPayload, { root: true })

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async technicalUpdate({ state, commit, dispatch }: any, payload: ITechnicalUpdatePayload) {
      try {
        const request = {
          appToken: PushHelper.getAppToken(),
          mobileId: PushHelper.getMobileId(),
          timezoneId: dayjs.tz.guess(),
          mobileAppSource: FiftySourceHelper.fiftySource() ?? 'unknown',
          hasRejectedNotifications: PushHelper.getAppToken() === null,
        } as FiftyApiClients.UpdateTechnicalInformationRequest
        const res = await payload.apiClients.userPropertiesApiClient.updateTechnicalInformation(request)
        if (!res?.result) return

        commit(COMMIT_INNER_REFRESH_MOBILE_APP_INSTALLATION, res.result!.hasAppInstalled)
      } catch (e) {
        console.error(e)
      }
    },
    incrementValidationStat({ state, commit, dispatch }: any, payload: IRefreshPayload) {
      if (payload.currentProgression) {
        commit(INNER_COMMIT_INCREMENT_VALIDATION_STAT_V2, payload.currentProgression)
      } else {
        commit(INNER_COMMIT_INCREMENT_VALIDATION_STAT)
      }

      if (state.user!.currentBadge!.validatedCount >= state.user!.currentBadge!.goal) {
        dispatch(INNER_DISPATCH_REFRESH_USER, payload)
      }
    },
    async getUserProfile({ state, commit }: any, payload: IGetUserProfilePayload) {
      try {
        const res = await payload.apiClients.userApiClient.getCurrentUser()
        if (res === null) {
          commit(COMMIT_INNER_SOFT_DISCONNECT, 'automatic-cant-get-user')
          return
        }

        const user = res.result
        const connectPayload = {
          user,
          segment: payload.segment,
        } as IConnectPayload

        commit(COMMIT_INNER_CONNECT, connectPayload)

        if (user) {
          const newLocale = getLanguageStringFromDto(user.language)
          const currentRouteLocale = payload.router.currentRoute.value.params.lang?.toString() as i18nLocale

          if (
            !isLocaleToShowTranslatedKeys(currentRouteLocale) &&
            (newLocale !== payload.router.currentRoute.value.params.lang || newLocale !== currentLocale.value)
          ) {
            setLocale(newLocale)
          }
        }

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async refresh({ commit, dispatch }: any, payload: IRefreshPayload) {
      try {
        const res = await payload.apiClients.userApiClient.getCurrentUser()

        if (res === null) {
          commit(COMMIT_INNER_SOFT_DISCONNECT, 'automatic-cant-refresh')
          return
        }

        const user = res.result
        const connectPayload = {
          user,
          segment: payload.segment,
        } as IConnectPayload

        commit(COMMIT_INNER_CONNECT, connectPayload)
        return connectPayload
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async logOut({ commit }: any, payload: ILogOutPayload) {
      try {
        await payload.apiClients.accountApiClient.logout()
      } catch {
        // do nothing
      }

      commit(COMMIT_INNER_HARD_DISCONNECT)

      if (localStorage.getItem(USER_LOGGED_WITH_TEAMS_KEY)) {
        payload.router.push({
          name: PAGE_USER_WITH_TEAMS_SIGNUP,
        })
      } else {
        payload.router.push({ name: PAGE_LOGIN })
      }
      commit(CLEAR_SESSION, null, { root: true })
      commit(CLEAR_CHALLENGES, null, { root: true })
      return true
    },
    async refreshTeamsInstallation({ commit }: any, payload: IRefreshInstallationPayload) {
      try {
        const res: any = await payload.apiClients.userPropertiesApiClient.refreshTeamsInstalled()
        if (!res || !res.result) {
          return
        }

        commit(COMMIT_INNER_REFRESH_TEAMS_INSTALLATION, res.result.hasTeamsInstalled)
      } catch (e) {
        console.error(e)
      }
    },
    async refreshMobileAppInstallation({ commit }: any, payload: IRefreshInstallationPayload) {
      try {
        const res: any = await payload.apiClients.userPropertiesApiClient.refreshMobileAppInstalled()
        if (!res || !res.result) {
          return
        }

        commit(COMMIT_INNER_REFRESH_MOBILE_APP_INSTALLATION, res.result.hasMobileAppInstalled)
      } catch (e) {
        console.error(e)
      }
    },
    incrementAcceptationStat({ commit }: any) {
      commit(COMMIT_INNER_ACCEPTATION_STAT)
    },
  },
  getters: {
    isInit(state: ILoginModuleState): boolean {
      return state.isInit
    },
    getUser(state: ILoginModuleState): FiftyApiClients.UserDto | null {
      return state.user // Assume connected if not initiated
    },
    isAuthenticated(state: ILoginModuleState): boolean {
      return state.user !== null // Assume connected if not initiated
    },
    isUserOnBreak(state: ILoginModuleState): boolean {
      if (!state.user) return false
      if (!state.user.breakStart || !state.user.breakEnd) return false

      // Return true if today is between start/end
      return state.user.breakStart <= new Date() && new Date() < state.user.breakEnd
    },
    isBreakEnabled(state: ILoginModuleState): boolean {
      if (!state.user) return false
      if (!state.user.breakStart || !state.user.breakEnd) return false

      // Return true if today is before end
      return new Date() < state.user.breakEnd
    },
    canAccessAdminBo(state: ILoginModuleState): boolean | null | undefined {
      return state.user !== null && state.user.userRights && state.user.userRights.canAccessBackoffice
    },
    hasMoreThanOneCourse: (state: ILoginModuleState) => () =>
      state.user !== null && state.user.userCampaigns && state.user.userCampaigns.length > 1,
  },
}
