import type { RouteLocationNormalized } from 'vue-router'
import { $segment } from '@/globalProperties'

const DEFAULT_PAGE_TITLE = 'Fifty'

export default (to: RouteLocationNormalized) => {
  if (to.meta.title) {
    document.title = (to.meta.title as string) || DEFAULT_PAGE_TITLE
  }
  if (to.name) {
    $segment.pageHasLoaded(to.name as string, to.params)
  }
}
