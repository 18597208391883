import { createStore } from 'vuex'
import { Login } from './modules/Login'
import { ChallengeSession } from '@/store/modules/ChallengeSession'
import { ChallengesStore } from '@/store/modules/ChallengesStore'
import { OnBoardingStore } from '@/store/modules/OnBoardingStore'
import { HabitsStore } from '@/store/modules/HabitsStore'

const store = createStore({
  strict: true,
  modules: {
    Login,
    ChallengeSession,
    ChallengesStore,
    OnBoardingStore,
    HabitsStore,
  },
})
export default store
